import React, { Component } from 'react';
import { Col, Row } from 'reactstrap'


class TourList extends Component {
  render() {

    return (
          <Row className="py-2 mx-auto">
               <Col md={12} xl={4} className="px-1">
                 <div className="tour-module">
                    <div className="py-5 p-md-3 h-100 text-center d-flex flex-column justify-content-center">
                      <h2 className="bold">Atlantic City, NJ<br/> Sound Waves at<br/> Hard Rock </h2>
                        <p>Spring 2020 dates postponed due to ongoing Coronavirus outbreak. New dates TBA.</p>
                      {/*<p>27 MARCH -<br/> 5 APRIL 2020 </p>*/}
                      {/*<div><a href="https://www.ticketmaster.com/artist/2297630?venueId=17091&_ga=2.246264007.2116575945.1579630821218619083.1569867348&_gac=1.95584622.1578683066.CjwKCAiA3uDwBRBFEiwA1VsajJ8k7hbc3RQhUqvBk-5w7g4cP6dcMlS0CtiACPVYVC8qOxLtAnFrRhoCX3AQAvD_BwE" target="_blank" rel="noopener noreferrer"  className="btn btn--yellow text-uppercase">BOOK TICKETS</a></div>*/}
                      {/*<div><a href="https://www.hardrockhotels.com/atlantic-city/broadway-at-hard-rock" target="_blank" rel="noopener noreferrer"  className="btn btn--yellow text-uppercase mt-3">VENUE</a></div>*/}

                    </div>
                    </div>
                  </Col>
                  <Col md={12} xl={4} className="px-1">
                  <div className="tour-module">
                    <div className="py-5 p-md-3 h-100 text-center d-flex flex-column justify-content-center">
                      <h2 className="bold">Hollywood, FL<br/>Hard Rock <br/>Live</h2>
                        <p>Spring 2020 dates postponed due to ongoing Coronavirus outbreak. New dates TBA.</p>
                      {/*<p>7 APRIL - <br/> 19 APRIL 2020</p>*/}
                      {/*<div><a href="https://www.ticketmaster.com/search?geoHash=dhwu&sort=date%2Casc&radius=10000&unit=miles&tab=events&q=Bat%2BOut%2BOf%2BHell%2BThe%2BMusical%2B(Touring)%2BHard%2BRock%2BLive&daterange=all" target="_blank" rel="noopener noreferrer"  className="btn btn--yellow text-uppercase">BOOK TICKETS</a></div>*/}
                      {/*<div><a href="https://www.seminolehardrockhollywood.com/events/bat-out-of-hell" target="_blank" rel="noopener noreferrer"  className="btn btn--yellow text-uppercase mt-3">VENUE</a></div>*/}
                    </div>
                    </div>
                  </Col>
                  
                  <Col md={12} xl={4} className="px-1">
                  <div className="tour-module">
                    <div className="py-5 p-md-3 h-100 text-center d-flex flex-column justify-content-center">
                      <h2 className="bold">Tampa, FL<br/> Hard Rock <br/>Event Center</h2>
                        <p>Spring 2020 dates postponed due to ongoing Coronavirus outbreak. New dates TBA.</p>
                      {/*<p>21 APRIL - <br/> 26 APRIL 2020</p>*/}
                      {/*<div><a href="https://www.ticketmaster.com/bat-out-of-hell-the-musical-touring-tickets/artist/2297630?venueId=107701&CAMEFROM=CFC_TAMPA_HARDROCK_WEB_UKWEBSITE_BOOH" target="_blank" rel="noopener noreferrer" className="btn btn--yellow text-uppercase">BOOK TICKETS</a></div>*/}
                      {/*<div><a href="https://www.seminolehardrocktampa.com/events/bat-out-of-hell-the-musical" target="_blank" rel="noopener noreferrer"  className="btn btn--yellow text-uppercase mt-3">VENUE</a></div>*/}
                    </div>
                    </div>
                  </Col>
          </Row>
    )
  }
}

export default TourList;